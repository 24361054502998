import { useEffect, useRef, useState } from "react";
import { object, any } from "prop-types";
import * as R from "ramda";
import StaticMapImg from "../StaticMapImg/StaticMapImg.jsx";
import BackgroundImage from "gatsby-background-image";
const propTypes = { image: object, department: object, latLng: any };

const BannerSplitImage = (props) => {
  // eslint-disable-next-line react/prop-types
  const { image, latLng, department } = props;
  const imgContainer = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!R.isNil(imgContainer)) {
      setLoaded(true);
      setHeight(Math.ceil(imgContainer.current.getBoundingClientRect().height));
      setWidth(Math.ceil(imgContainer.current.getBoundingClientRect().width));
    }
  }, []);

  return (
    <div ref={imgContainer} className="banner__image-container">
      {R.isNil(latLng) ? (
        <BackgroundImage
          className="banner__img"
          fluid={R.pathOr(null, ["fluid"])(image)}
          style={{
            backgroundSize: "cover",
          }}
        />
      ) : (
        <StaticMapImg
          apiKey="AIzaSyDQJQlVEHYRQZywPHuSsHL1Lvx0S_YxZ30"
          zoom={12}
          center={R.values(latLng)}
          width={width}
          height={height}
          alt={`Map of ${department.state}`}
        />
      )}
    </div>
  );
};

BannerSplitImage.propTypes = propTypes;
export default BannerSplitImage;
