import * as R from "ramda";
import { object, string, array, shape } from "prop-types";
import { graphql } from "gatsby";
import {
  extractRichText,
  extractBanner,
  extractDatasetInfo,
  extractResourceItem,
} from "../../common/contentful-extract.jsx";
import { ChartDataProvider } from "../../common/ChartData.jsx";

import DepartmentPage from "../../components/DepartmentPage/DepartmentPage.jsx";

const propTypes = {
  data: shape({
    ctaBanner: object,
    datasetInfo: object,
    id: string,
    image: object,
    lineChartTitle: object,
    racialDataTitle: object,
    genderDataTitle: array,
    relatedArticle: array,
    sectionTitle1: object,
    sectionTitle2: object,
    trendsTabelTitle: object,
    departmentListingSectionText: object,
    extractRichText: object,
    url: string,
  }).isRequired, // exported query below
};

const DepartmentTemplate = (props) => {
  const data = R.compose(
    R.evolve({
      sectionTitle1: extractRichText,
      sectionTitle2: extractRichText,
      lineChartTitle: extractRichText,
      racialDataTitle: extractRichText,
      genderDataTitle: extractRichText,
      ctaBanner: extractBanner,
      datasetInfo: extractDatasetInfo,
      datasetInformation: extractRichText,
      trendsTableTitle: extractRichText,
      relatedArticle: extractResourceItem,
      departmentListingSectionText: extractRichText,
      additionalPageContent: extractRichText,
    }),
    R.defaultTo({}),
    R.path(["contentfulDepartmentPage"]),
  )(props.data);

  const formattedFootnotes =
    data.footnotes &&
    data.footnotes.map((x) => {
      return extractRichText(x.footnoteItem);
    });

  const deptList = R.pathOr(
    [],
    ["allContentfulDepartmentPage", "nodes"],
    props.data,
  );

  const lastAnalyzed = R.pathOr(
    [],
    ["contentfulDataExplorerPage", "lastAnalyzed"],
    props.data,
  );

  return (
    <ChartDataProvider>
      <DepartmentPage
        formattedFootnotes={formattedFootnotes}
        deptList={deptList}
        lastAnalyzed={lastAnalyzed}
        {...data}
      />
    </ChartDataProvider>
  );
};

DepartmentTemplate.propTypes = propTypes;
export default DepartmentTemplate;

// !! it's important that __typename and contentful_id is in there so renderRichText works!
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/src/rich-text.js
export const query = graphql`
  query ($id: String!) {
    contentfulDataExplorerPage {
      lastAnalyzed(locale: "en-US", formatString: "MMMM DD, yyyy")
    }
    contentfulDepartmentPage(id: { eq: $id }) {
      cityId {
        ... on ContentfulCity {
          id
          name
          slug
          __typename
        }
      }
      image {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        file {
          url
        }
      }
      departmentTitle
      id
      url
      ctaBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
      departmentListingSectionText {
        raw
      }
      sectionTitle1 {
        raw
      }
      sectionTitle2 {
        raw
        references {
          ...Link
        }
      }
      racialDataTitle {
        raw
      }
      genderDataTitle {
        raw
      }
      lineChartTitle {
        raw
      }
      trendsTableTitle {
        raw
      }
      relatedArticle {
        ... on ContentfulResourceItem {
          id
          description {
            raw
          }
          title
          link {
            ...Link
          }
          resourceTags {
            ... on ContentfulCity {
              id
              name
              slug
              __typename
            }
            ... on ContentfulTopic {
              id
              name
              slug
              __typename
            }
          }
          featuredImage {
            fluid(maxWidth: 570) {
              aspectRatio
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
              base64
            }
          }
        }
      }
      datasetInfo {
        buttonText
        content {
          raw
        }
      }
      additionalPageContent {
        raw
        references {
          ...Link
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            fixed {
              src
              srcSet
              width
              height
              srcSetWebp
              srcWebp
              tracedSVG
            }
            file {
              url
              fileName
              details {
                size
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      department {
        id
        coordinates {
          lat
          lon
        }
        state
        name
      }

      footnotes {
        footnoteItem {
          raw
        }
      }
    }
    allContentfulDepartmentPage {
      nodes {
        id
        department {
          id
          name
          state
        }
        url
      }
    }
  }
`;
