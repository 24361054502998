import React, { useEffect, useState } from "react";
import { object, array, string } from "prop-types";
import Container100 from "../Container/Container100.jsx";
import Container303030 from "../Container/Container303030.jsx";
import BannerSplit from "../BannerSplit/BannerSplit.jsx";
import Banner from "../Banner/Banner.jsx";
import Page from "../Page/Page.jsx";
import Section from "../Section/Section.jsx";
import ImageBlock from "../ImageBlock/ImageBlock.jsx";
import ChartContent from "../ChartContent/ChartContent.jsx";
import DepartmentListing from "../DepartmentListing/DepartmentListing.jsx";
import PieChart from "../PieChart/PieChart.jsx";
import { useCreateDataArray } from "../../common/useFetchData.js";
import * as R from "ramda";
import "aos/dist/aos.css";
import AOS from "aos";
import "./department-page.scss";
import { useChartData } from "../../common/ChartData.jsx";
import { COLORS } from "../../common/chart.js";

const propTypes = {
  department: object,
  image: object,
  sectionTitle1: array,
  sectionTitle2: array,
  lineChartTitle: array,
  racialDataTitle: array,
  ctaBanner: object,
  datasetInfo: object,
  trendsTableTitle: array,
  imageBlock: object,
  genderDataTitle: array,
  relatedArticle: array,
  deptList: array,
  departmentListingSectionText: array,
  additionalPageContent: array,
  id: string,
  lastAnalyzed: string,
  cityId: object,
  formattedFootnotes: array,
};

const DepartmentPage = (props) => {
  const {
    id,
    department,
    image,
    sectionTitle1,
    sectionTitle2,
    racialDataTitle,
    ctaBanner,
    lineChartTitle,
    datasetInfo,
    genderDataTitle,
    trendsTableTitle,
    relatedArticle,
    departmentListingSectionText,
    deptList,
    additionalPageContent,
    lastAnalyzed,
    cityId,
    formattedFootnotes,
  } = props;
  const { chartData, setChartData, apiUrl } = useChartData();

  const deptId = cityId?.slug;

  const latLng = department?.coordinates
    ? [department.coordinates.lat, department.coordinates.lon]
    : null;
  const [charts, setCharts] = useState(false);
  const dataResponse = useCreateDataArray(
    [
      `${apiUrl}/pie-chart?indicator=201&timeframeid=1&locationid=${deptId}`,
      `${apiUrl}/pie-chart?indicator=201&timeframeid=2&locationid=${deptId}`,
      `${apiUrl}/pie-chart?indicator=201&timeframeid=3&locationid=${deptId}`,
      `${apiUrl}/pie-chart?indicator=202&timeframeid=1&locationid=${deptId}`,
      `${apiUrl}/pie-chart?indicator=202&timeframeid=2&locationid=${deptId}`,
      `${apiUrl}/pie-chart?indicator=202&timeframeid=3&locationid=${deptId}`,
    ],
    { method: "GET" },
  );

  const { data } = dataResponse;

  const mapColors = (charts) => {
    const colorMap = {};
    //finding the max number of sub indicators to map colors to
    const maxLength = charts
      .map((x) => x.data)
      .sort((a, b) => b.length - a.length);
    maxLength[0].map((chart, i) => {
      return (colorMap[chart.subDistributionValue] = COLORS[i]);
    });
    return colorMap;
  };

  useEffect(() => {
    if (deptId !== undefined && deptId !== chartData.locationid) {
      setChartData({
        locationid: deptId,
      });
    }
  }, [data, deptId]);

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    if (data) {
      setCharts(
        R.compose(
          R.map(R.compose(R.flatten, R.pluck("chart"))),
          R.map(R.reject(R.isNil), R.reject(R.isEmpty)),
        )(R.splitAt(3, data)),
      );
    }
  }, [data]);

  return department && data && deptId ? (
    <Page highlightLogo={true} title="Department">
      {department && (
        <BannerSplit
          aos={true}
          title={department.name}
          subtitle={`${department.state}`}
          image={image}
          latLng={latLng}
          department={department}
          isHeader={true}
        />
      )}
      {sectionTitle1 && (
        <Section
          aos={true}
          className="department__section deparment__wrapper department__title-container"
          theme="light"
          containerModifier="section__container--b-0"
          title={sectionTitle1}
        />
      )}
      {deptId && (
        <ChartContent
          chartProps={{
            page: "department",
            datasetInfo,
            lineChartTitle,
            trendsTableTitle,
            lastAnalyzed,
            defaultIndicator: 300,
            formattedFootnotes: formattedFootnotes,
            indicatorItems: [
              { id: 300, value: "Number of incidents" },
              { id: 200, value: "Number of individuals affected" },
            ],
            locationId: deptId,
          }}
          defaultBreakdownLabel={cityId.name}
        />
      )}
      {sectionTitle2 && (
        <Section
          aos={true}
          className="department__section deparment__wrapper department__title-container "
          theme="light"
          containerModifier="section__container--b-0"
          title={sectionTitle2}
        />
      )}
      <Section
        aos={true}
        className="department__section department__graph-container department__title-container "
        theme="light"
        containerModifier="section__container--sm section__container--b-0"
      >
        <Container100 className="department__title-container ">
          {racialDataTitle}
        </Container100>
        {charts && charts[0] !== undefined ? (
          <>
            <Container303030
              className="department__title-container "
              items={charts[0].map((i) => {
                return i ? (
                  <React.Fragment key={`pie-${i.timeFrame}-race`}>
                    <p className="department__graph-year">{i.timeFrame}</p>
                    <PieChart colors={mapColors(charts[0])} data={i.data} />
                  </React.Fragment>
                ) : (
                  false
                );
              })}
            />
          </>
        ) : (
          false
        )}
      </Section>
      <Section
        aos={true}
        className="department__section department__graph-container"
        theme="light"
        containerModifier="section__container--sm"
      >
        <Container100 className="department__title-container ">
          {genderDataTitle}
        </Container100>
        {charts && charts[1] !== undefined ? (
          <Container303030
            className="department__title-container"
            items={charts[1].map((i) => {
              return i ? (
                <React.Fragment key={`pie-${i.timeFrame}-gender`}>
                  <p className="department__graph-year">{i.timeFrame}</p>
                  <PieChart colors={mapColors(charts[1])} data={i.data} />
                </React.Fragment>
              ) : (
                false
              );
            })}
          />
        ) : (
          false
        )}
      </Section>
      {relatedArticle.length > 0 && (
        <Section aos={true} theme="light">
          {relatedArticle.map((related, i) => {
            const { title } = related;

            return (
              <ImageBlock
                aos={true}
                className={"image-block--related-article"}
                key={`related-${i}`}
                isReverse={i % 2 === 0}
                smallImages={true}
                tags={related.resourceTags}
                buttonModifier={"btn--text"}
                {...related}
              />
            );
          })}
        </Section>
      )}
      <DepartmentListing
        deptListText={departmentListingSectionText}
        deptList={R.filter((dept) => id !== dept.id)(deptList)}
      />
      <Banner {...ctaBanner} />
      {additionalPageContent && (
        <Section aos={true} className="department__section" theme="light">
          <Container100 className="department__bottom-rich-text">
            {additionalPageContent}
          </Container100>
        </Section>
      )}
    </Page>
  ) : (
    false
  );
};

DepartmentPage.propTypes = propTypes;
export default DepartmentPage;
