import { bool, node, object, oneOf, string } from "prop-types";
import cx from "classnames";
import Container5050 from "../Container/Container5050.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import BannerSplitImage from "./BannerSplitImage.jsx";
import "./banner-split.scss";
const defaultProps = {};

const propTypes = {
  aos: bool, // https://github.com/michalsnik/aos
  backgroundSize: oneOf(["", "contain", "cover"]),
  children: node,
  className: string,
  content: node,
  link: object, // link object for <ContentfulLink>
  bannerImg: node,
  subtitle: node,
  textColor: oneOf(["Light", "Dark"]),
  title: node,
  mapData: object,
  isHeader: bool,
};

const BannerSplit = (props) => {
  const {
    aos,
    backgroundSize,
    className,
    content,
    link,
    subtitle,
    textColor,
    title,
    children,
    isHeader,
    ...otherProps
  } = props;

  const Wrapper = (props) => <div {...props} />;

  return (
    <Wrapper
      className={cx("banner", "banner--split", className, {
        "banner--dark-text": textColor === "Dark",
      })}
    >
      {textColor !== "Dark" && backgroundSize === "cover" && (
        <div className="banner__overlay"></div>
      )}
      <Container5050
        className="container container--bleed"
        left={
          <div className="container banner__container">
            <div className="banner__titles" data-aos={aos && "fade-up"}>
              {isHeader ? (
                <h1 className="banner__title fancy-bold">{title}</h1>
              ) : (
                <div className="banner__title fancy-bold">{title}</div>
              )}

              {subtitle && <div className="banner__subtitle">{subtitle}</div>}
            </div>
            <div data-aos={aos && "fade-up"}>
              {children}
              {content ? (
                <div className="banner__content steps-ol">{content}</div>
              ) : null}
              {link ? (
                <div className="banner__link-container">
                  <ContentfulLink
                    {...link}
                    className={cx("btn", {
                      // if there is a photo background, always use transparent btn
                      "btn--transparent":
                        textColor !== "Dark" && backgroundSize === "cover",
                    })}
                  />
                </div>
              ) : null}
            </div>
          </div>
        }
        right={
          window.innerWidth >= 910 ? (
            <div className="banner__container">
              <BannerSplitImage {...otherProps} />
            </div>
          ) : (
            false
          )
        }
      >
        {children}
      </Container5050>
    </Wrapper>
  );
};

BannerSplit.defaultProps = defaultProps;
BannerSplit.propTypes = propTypes;
export default BannerSplit;
