/**
 * http://bl.ocks.org/dbuezas/9306799
 */

import * as R from "ramda";
import { scaleOrdinal } from "d3-scale";
import { arc, pie } from "d3-shape";

import { formatPercentageNice } from "../../common/format.js";
import { COLORS } from "../../common/chart.js";
import "./pie-chart.scss";

import { useRef } from "react";
import { arrayOf, number, shape, string, object } from "prop-types";

const propTypes = {
  data: arrayOf(
    shape({
      subDistributionId: string.isRequired,
      subDistributionValue: string.isRequired,
      value: number.isRequired,
    }),
  ).isRequired,
  colors: object,
  size: number,
};

const defaultProps = {
  size: 150,
};

function midAngle(d) {
  return d.startAngle + (d.endAngle - d.startAngle) / 2;
}

function pointPosition(d, radius) {
  const theta = midAngle(d) - Math.PI / 2; // have to rotate
  return [radius * Math.cos(theta), radius * Math.sin(theta)];
}

const PieChart = (props) => {
  const { data, size, colors } = props;
  const chartRef = useRef();
  //this is to prevent overlapping labels
  const GLOBAL_TOP_ARR = [];

  const activeClass = "pie-chart__label--active";
  const activeLineClass = "pie-chart__line--active";
  const activeSliceClass = "pie-chart__slice--active";
  const inactiveSliceClass = "pie-chart__slice--inactive";

  const width = size;
  const height = size;
  const radius = Math.min(width, height) / 2;

  const margin = { top: 50, right: 75, bottom: 50, left: 75 };

  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;
  const translate = {
    x: width / 2 + margin.left,
    y: height / 2 + margin.top,
  };

  const arcGenerator = arc().innerRadius(0).outerRadius(radius);

  const arcs = pie()
    .value((d) => d.value)
    .sort(null)(data);

  const colorDomain = data.map((d) => d.subDistributionId);

  const reduceColors = (colors) =>
    R.pickAll(
      data.map((x) => x.subDistributionValue),
      colors,
    );

  const usableColors = colors ? R.values(reduceColors(colors)) : COLORS;
  const colorScale = scaleOrdinal().domain(colorDomain).range(usableColors);

  const total = R.compose(R.sum, R.pluck("value"))(data);

  const hideLabel = (targetId) => {
    const targetLabel = chartRef.current.querySelector(`#${targetId}`);
    const targetLabelLine = chartRef.current.querySelector(`#line-${targetId}`);
    const allSlices = chartRef.current.querySelectorAll(".pie-chart__slice");
    const targetPieSlice = chartRef.current.querySelector(`#slice-${targetId}`);

    allSlices.forEach((x) => x.classList.remove(inactiveSliceClass));
    targetPieSlice.classList.remove(activeSliceClass);

    targetLabel.classList.remove(activeClass);
    targetLabelLine.classList.remove(activeLineClass);
  };

  const showLabel = (targetId) => {
    const labelsToRemove = chartRef.current.querySelectorAll(`.${activeClass}`);

    const linesToRemove = chartRef.current.querySelectorAll(
      `.${activeLineClass}`,
    );

    const opacityToRemove = chartRef.current.querySelectorAll(
      `.${inactiveSliceClass}`,
    );

    const activeOpacityToRemove = chartRef.current.querySelectorAll(
      `.${activeSliceClass}`,
    );

    labelsToRemove.forEach((x) => x.removeClass(activeClass));
    linesToRemove.forEach((x) => x.removeClass(activeLineClass));
    opacityToRemove.forEach((x) => x.removeClass(inactiveSliceClass));
    activeOpacityToRemove.forEach((x) => x.removeClass(activeSliceClass));

    const targetLabel = chartRef.current.querySelector(`#${targetId}`);
    const targetLabelLine = chartRef.current.querySelector(`#line-${targetId}`);
    const allSlices = chartRef.current.querySelectorAll(".pie-chart__slice");
    const targetPieSlice = chartRef.current.querySelector(`#slice-${targetId}`);

    allSlices.forEach((x) => x.classList.add(inactiveSliceClass));
    targetPieSlice.classList.add(activeSliceClass);
    targetLabel.classList.add(activeClass);
    targetLabelLine.classList.add(activeLineClass);
  };

  const handleLabelDisplay = (targetId) => {
    const targetLabel = chartRef.current.querySelector(`#${targetId}`);
    targetLabel.classList.contains(activeClass)
      ? hideLabel(targetId)
      : showLabel(targetId);
  };

  return (
    <div className="pie-chart" ref={chartRef}>
      <div
        className="pie-chart__chart"
        style={{ width: svgWidth, height: svgHeight }}
      >
        <svg
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        >
          <g transform={`translate(${translate.x}, ${translate.y})`}>
            <g className="pie-chart__slices">
              {arcs.map((d) => {
                return (
                  <path
                    className="pie-chart__slice"
                    id={`slice-id${d.data.subDistributionId}`}
                    data-id={`id${d.data.subDistributionId}`}
                    onMouseEnter={() =>
                      showLabel(`id${d.data.subDistributionId}`)
                    }
                    onMouseLeave={() =>
                      hideLabel(`id${d.data.subDistributionId}`)
                    }
                    key={d.data.subDistributionId}
                    d={arcGenerator(d)}
                    fill={colorScale(d.data.subDistributionId)}
                  />
                );
              })}
            </g>
            <g className="pie-chart__lines">
              {arcs.map((d, k) => {
                const yOffset = k % 2 == 0 ? -3 : 0;
                const [x1, y1] = pointPosition(d, radius + 5);
                const [x2, y2] = pointPosition(d, radius + 20);

                return (
                  <line
                    id={`line-id${d.data.subDistributionId}`}
                    key={d.data.subDistributionId}
                    className="pie-chart__line"
                    {...{ x1, y1, x2, y2 }}
                  />
                );
              })}
            </g>
          </g>
        </svg>

        {/* labelTopArr, setlabelTopArr */}
        <div className="pie-chart__labels">
          {arcs.map((d, k) => {
            const [x, y] = pointPosition(d, radius + 40);
            // const xOffset = x + (midAngle(d) < Math.PI ? 10 : -10);
            const yOffset = k % 2 == 0 ? y - 5 : y + 5;
            const getAngle = (d) => {
              return ((180 / Math.PI) * (d.startAngle + d.endAngle)) / 2 - 90;
            };
            const side = midAngle(d) < Math.PI ? "right" : "left";

            const hasOverlap = GLOBAL_TOP_ARR.includes(Math.round(y));
            //if y is already in the array (going to overlap), check if it's positive or negative to add/subtract 16 to clear the neighboring label
            const top = hasOverlap ? (y > 0 ? y + 16 : y - 16) : y;
            GLOBAL_TOP_ARR.push(Math.round(top));

            return (
              <div
                className="pie-chart__label"
                id={`id${d.data.subDistributionId}`}
                key={d.data.subDistributionId}
                style={{
                  position: "absolute",
                  top: `calc(50% + ${top}px)`,
                  textAlign: side === "left" ? "right" : "left",
                  left: side === "left" ? "0" : `calc(50% + ${x}px)`,
                  right: side === "right" ? "0" : `calc(50% - ${x}px)`,
                }}
              >
                {d.data.subDistributionValue} (
                {formatPercentageNice(d.data.value / total)})
              </div>
            );
          })}
        </div>
      </div>
      <div className="pie-chart__legend">
        {arcs.map((d) => {
          return (
            <div
              key={d.data.subDistributionId}
              className="pie-chart__legend-item"
              data-id={`id${d.data.subDistributionId}`}
              tabIndex="0"
              role="button"
              onClick={() =>
                handleLabelDisplay(`id${d.data.subDistributionId}`)
              }
              onKeyDown={(e) => {
                e.code === "Enter" &&
                  handleLabelDisplay(`id${d.data.subDistributionId}`);
              }}
              onMouseEnter={() => showLabel(`id${d.data.subDistributionId}`)}
              onMouseLeave={() => hideLabel(`id${d.data.subDistributionId}`)}
            >
              <span
                className="pie-chart__legend-color"
                style={{
                  backgroundColor: colorScale(d.data.subDistributionId),
                }}
              />
              <div>{d.data.subDistributionValue}</div>

              <div className="pie-chart__legend-value">
                {formatPercentageNice(d.data.value / total)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PieChart.defaultProps = defaultProps;
PieChart.propTypes = propTypes;
export default PieChart;
