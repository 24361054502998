import { node, string } from "prop-types";
import cx from "classnames";

import "./container.scss";

const propTypes = {
  as: string,
  className: string,
  items: node,
};

const Container303030 = (props) => {
  const { className, items, ...otherProps } = props;

  return (
    <div
      className={cx("container container--30-30-30", className)}
      {...otherProps}
    >
      {items &&
        items.map((item) => (
          <div key={item.key} className="container__col container__col--30">
            {item}
          </div>
        ))}
    </div>
  );
};

Container303030.propTypes = propTypes;
export default Container303030;
